import { useEffect, useState } from "react";
import { getNewsInner } from "../Service/Api";
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer' 
const News = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
 
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 
    useEffect(() => {
       const currentYear = new Date().getFullYear();
       const Year = `${currentYear}`;
       setSelectedYear(Year);
       console.log("get current Year", Year);
    }, []);
 
    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getNewsInner();
            setData(galleryData);
            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const year = new Date(item.fromDate).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);
 
            const uniquemonth = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const month = new Date(item.fromDate).getMonth();
                        return [month];
                    } else {
                        return [];
                    }
                }))
            );
            const filterMonth = uniquemonth.sort((a, b) => a - b);
            const uniqueMonths = filterMonth.map(month => monthNames[month]);
             
           
            setMonthOptions(uniqueMonths);
        };
        fetchData();
    }, []);
 
    useEffect(() => {
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate && item.fromDate.trim() !== "") {
                    const year = new Date(item.fromDate).getFullYear();
                    const month = new Date(item.fromDate).getMonth() + 1;
                    return parseInt(selectedYear) === year && (selectedMonth === "All" || monthNames[parseInt(selectedMonth) - 1] === monthNames[month - 1]);
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate && item.fromDate.trim() !== "") {
                    const month = new Date(item.fromDate).getMonth() + 1;
                    return monthNames[parseInt(selectedMonth) - 1] === monthNames[month - 1];
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);
 
    const emptyArray = [
        {
            description: "“Educating the mind without educating the heart is no education at all.” Sacred Heart has been educating boys and girls since 1990; though much has changed over the years our mission remains the same. Sacred Heart is committed to developing students of high academic achievement, intellectual curiosity, and strong moral character. As a result, year after year Sacred Heart sends our respectful, responsible and compassionate young adults, who are well prepared to tackle the challenges that lay ahead. Since arriving at Sacred Heart in 2016, I have had the opportunity to observe this unique institution up close. I have seen its commitment to academic excellence, its dynamic student body, and incredibly committed faculty and a loving community where the students and families are proud and supportive of one another. As I walk down the hallways of the school every day, I can hear the chatter of eager minds; the shouts of excitement from the victorious athletes, the thumb of dancers feed and the sound of melodious voices harmonizing the perpetual energy, movement and enthusiasm permeate the atmosphere. Ours is a school with difference. We value creativity and innovation and strive to nurture them in our students. Sacred Heartians are groomed to become confident, articulate and enlightened young citizens ready to step into the global society with dignity, honour and integrity. Aristotle once said “Educating the mind without educating the heart is no education at all”. Even as we impart education to match the advancement in technology and globalization, we march our children ahead with Sacred Heart ethos of moral values and principles. We Endeavour constantly to instill these qualities in our children. We pride ourselves to help them grow and develop into sensitive, responsible God loving citizens of the future. May the Sacred Heart of Jesus bless us abundantly in all our endeavourers.",
            attachments: ["director.jpg"]
        }
    ];
 
  return (
      <>
      <Header/>
        <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> News </li>
            </ul>
       </div>
  <div className="innersec">
  <div className="container">
          <div className="row">
             <div className="col-lg-12">
                <h1> News  </h1>
           </div>
       </div>
       <div className="row tabs-dynamic">
       <div className="col-md-3">
           <div className="count-val">
               <p>
                   Total Count: {filterData.length}
               </p>
           </div>
       </div>
       <div className="col-md-3">
           <div className="month-selection">
           <select
                                   value={selectedYear}
                                   onChange={(e) => setSelectedYear(e.target.value)}
                                   className="yearSelection">
                                   <option value={"All"}>All</option>
                                   {yearRanges.map((yr) => (
                                       <option key={yr} value={yr}>{yr}</option>
                                   ))}
                               </select>
           </div>
           
       </div>
       <div className="col-md-3">
           <div className="month-selection">
           <select
                                   value={selectedMonth}
                                   onChange={(e) => setSelectedMonth(e.target.value)}
                                   className="monthSelect">
                                   <option value={"All"}>All</option>
                                   {monthOptions.map((month, index) => (
                                       <option key={index} value={index + 1}>{month}</option>
                                   ))}
                               </select>
           </div>
       </div>
       <div className="col-md-3">
           <div className="searchBox">
           <input
                                   type="text"
                                   id="myInput"
                                   name="name"
                                   value={searchQuery}
                                   onChange={(e) => setSearchQuery(e.target.value)}
                                   placeholder="Search Here.."
                               />
           </div>
       </div>
   </div>
   <div className="row years year2023 showYear">
   {filterData.length > 0 ? (
                           filterData.map((item, index) => (
       <div className="col-md-6 newsCount " key={index}>
           <div className="news-blk">
               <div className="news-head">
                   <h2>{new Date(item.fromDate).getDate()}<span>{new Date(item.fromDate).toLocaleString('default', { month: 'short' })}</span> </h2>
                   <h3>{item.title}</h3>
               </div>
               <div className="news-content">
                   <p>{item.description}</p>
               </div>
               {item.attachments && item.attachments.length > 0 &&(
               <div className="attachment">
               <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
               </div>
               )}
           </div>
       </div>
       ))
    ) : (
            emptyArray.map((data, index) => (
                <div className="col-md-6 newsCount">
           <div className="news-blk">
               <div className="news-head">
                   <h2>DD<span>MM</span></h2>
                   <h3>News Heading</h3>
               </div>
               <div className="news-content">
                   <p>Stay connected for more updates!</p>
               </div>
               <div className="attachment">
                   <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
               </div>
           </div>
       </div>
            ))
        )}
    
      </div>
     </div>
  </div>
  <Footer />
      </>
  )
}

export default News
