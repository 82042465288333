import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Preamble = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Preamblebg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Preamble </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Preamble </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12"> 
               <p><b>WE, THE PEOPLE OF INDIA,</b> having solemnly resolved to constitute India a SOVEREIGN SOCIALIST SECULAR DEMOCRATIC REPUBLIC and to secure to all its citizens:</p>
               <p><b>JUSTICE,</b> social, economic and political;<br />
              <b> LIBERTY</b> of thought, expression, belief, faith and worship;<br />
               <b>EQUALITY</b> of status and of opportunity; and to promote among them all;<br />
               <b>FRATERNITY</b> assuring the dignity of the individual and the unity and integrity of the Nation;</p>
               <p><b>IN OUR CONSTITUENT ASSEMBLY</b> on this twenty-sixth day of November, <b>1969</b>, do HEREBY ADOPT, ENACT AND GIVE TO OURSELVES THIS CONSTITUTION.</p>
               </div>
                
              {/* <div className="col-lg-6"><a data-magnify="gallery" data-src="" data-group="a" href="Images/Preamble_1.JPG"><img src="images/Preamble_1.jpg" className="img-fluid mainimg" alt="Gandhi High School, Sidhi, MP"/></a> </div>
               <div className="col-lg-6"><a data-magnify="gallery" data-src="" data-group="a" href="Images/Preamble_2.JPG"><img src="images/Preamble_2.jpg" className="img-fluid mainimg" alt="Gandhi High School, Sidhi, MP"/></a> </div>
  */}  
               </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Preamble
