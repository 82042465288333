import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import Header from "../Component/Header";
import { Link } from "react-router-dom";
import Footer from "../Component/Footer";

const OtherToppers = () => {
    const [data, setData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("");
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const fetchData = async (year) => {
        try {
            const toppersData = await getTopper();
            console.log("Fetched Data:", toppersData); // Debug log
            const filteredData = toppersData.filter(
                (item) => item.sessionYear === year && item.class === "OtherToppers"
            );
            console.log("Filtered Data:", filteredData); // Debug log

            const sortedData = filteredData.sort((a, b) => b.division - a.division);
            setData(sortedData);

            const years = new Set(toppersData.map((item) => item.sessionYear));
            setYearRanges([...years]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;
        const yearRange = `${currentYear}-${nextYear}`;
        setSelectedYear(yearRange);
        fetchData(yearRange);
    }, []);

    useEffect(() => {
        if (!selectedYear) return;
        fetchData(selectedYear);
    }, [selectedYear]);

    useEffect(() => {
        const filteredData = data.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const sortedData = filteredData.sort((a, b) => b.division - a.division);
        setData(sortedData);
    }, [searchQuery]);

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <>
            <Header />
            <div className="innerslide Admissionbg">
                <ul className="breadcrumb">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>Class Toppers</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>Class Toppers</h1>
                        </div>
                        <div className="col-lg-6">
                            <div className="chooseyear">
                                <select
                                    id="chooseddlYear"
                                    onChange={handleYearChange}
                                    value={selectedYear}
                                >
                                    <option value="">Select Years</option>
                                    {yearRanges.map((yr, index) => (
                                        <option key={index} value={yr}>
                                            {yr}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="clr"></div>
                    <div id="showTwo" className="myDiv1 selected_year">
                        <div className="topperssec">
                            {data.length > 0 ? (
                                data.map((item, index) => (
                                    <div className="topperdiv" key={index}>
                                        <div className="topperper">
                                            <p>{item.division}</p>
                                        </div>
                                        <div className="topperdivimg">
                                            <img
                                                src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`}
                                                className="img-fluid"
                                                alt={`Toppers ${item.name}`}
                                            />
                                        </div>
                                        <div className="topperdivdesc">
                                            <p className="toppername">
                                                {item.name} <span>{item.stream}</span>
                                            </p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="topperdiv">
                                    <div className="topperper">
                                        <p>%</p>
                                    </div>
                                    <div className="topperdivimg">
                                        <img
                                            src="/Images/topper.jpg"
                                            className="img-fluid"
                                            alt="Placeholder"
                                        />
                                    </div>
                                    <div className="topperdivdesc">
                                        <p className="toppername">
                                            Name <span>Subject</span>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default OtherToppers;
