import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer' 
import { useState } from 'react';
const VideoGallery = () => {
    const [selectedOption ,setselectedOption] = useState('option2024');
    function handleOnchange(e){
       setselectedOption(e.target.value)
    }
  return (
      <>
      <Header/>
      <div className="innerslide"> 
          <ul className="breadcrumb">
              <li><Link to="/"> Home</Link> </li> 
              <li> News </li>
          </ul>
     </div>
<div className="innersec">
<div className="container">
            <div className="row">
               <div className="col-lg-6">
                  <h1> Video Gallery </h1>
             </div>
             <div className="col-lg-6">
             <div className="chooseyear">
             <select id="chooseddlYear" value={selectedOption} onChange={handleOnchange}>
                     <option value="option2024">2024</option>
                     <option value="option2023">2023</option>
              </select>
          </div>
             </div>
         </div> 
              
          
            {selectedOption === 'option2024' &&  <div className="row">
             <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                   <div className="video-gallery"> 
                      <Link to="#" target="_blank">
                       <i className="bi bi-play-circle-fill"></i> 
                       <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                       </div> 
                    </Link>
                    </div>
                 </div>
           <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                   <div className="video-gallery"> 
                      <Link to="#" target="_blank">
                       <i className="bi bi-play-circle-fill"></i> 
                       <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                       </div> 
                    </Link>
                    </div>
                 </div>
           <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                   <div className="video-gallery"> 
                      <Link to="#" target="_blank">
                       <i className="bi bi-play-circle-fill"></i> 
                       <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                       </div> 
                    </Link>
                    </div>
                 </div>
           <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                   <div className="video-gallery"> 
                      <Link to="#" target="_blank">
                       <i className="bi bi-play-circle-fill"></i> 
                       <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                       </div> 
                    </Link>
                    </div>
                 </div>
                 <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                   <div className="video-gallery"> 
                      <Link to="#" target="_blank">
                       <i className="bi bi-play-circle-fill"></i> 
                       <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                       </div> 
                    </Link>
                    </div>
                 </div>
                 <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                   <div className="video-gallery"> 
                      <Link to="#" target="_blank">
                       <i className="bi bi-play-circle-fill"></i> 
                       <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                       </div> 
                    </Link>
                    </div>
                 </div>
          </div>}
          {selectedOption === 'option2023' &&  <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                   <Link to="#" target="_blank">
                    <i className="bi bi-play-circle-fill"></i> 
                    <div> 
                     <h3>Title</h3> 
                     <p>27.02.2024</p> 
                    </div> 
                 </Link>
                 </div>
              </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                   <Link to="#" target="_blank">
                    <i className="bi bi-play-circle-fill"></i> 
                    <div> 
                     <h3>Title</h3> 
                     <p>27.02.2024</p> 
                    </div> 
                 </Link>
                 </div>
              </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                   <Link to="#" target="_blank">
                    <i className="bi bi-play-circle-fill"></i> 
                    <div> 
                     <h3>Title</h3> 
                     <p>27.02.2024</p> 
                    </div> 
                 </Link>
                 </div>
              </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                   <Link to="#" target="_blank">
                    <i className="bi bi-play-circle-fill"></i> 
                    <div> 
                     <h3>Title</h3> 
                     <p>27.02.2024</p> 
                    </div> 
                 </Link>
                 </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                   <Link to="#" target="_blank">
                    <i className="bi bi-play-circle-fill"></i> 
                    <div> 
                     <h3>Title</h3> 
                     <p>27.02.2024</p> 
                    </div> 
                 </Link>
                 </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                   <Link to="#" target="_blank">
                    <i className="bi bi-play-circle-fill"></i> 
                    <div> 
                     <h3>Title</h3> 
                     <p>27.02.2024</p> 
                    </div> 
                 </Link>
                 </div>
              </div>
       </div>}
               </div>
          </div>  
       <Footer />
      </>
  )
}

export default VideoGallery
