import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
//import modal from'../Assets/Images/modal-1.jpg'
import { getNotification } from '../Service/Api';
const Popup = () => {

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // Empty dependency array ensures this effect runs only once on mount  

  useEffect(() => {
    const getData = async () => {
      const datas = await getNotification();
      console.log(datas)
      setData(datas);
    };
    getData();
  }, []);

  console.log(data);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModalToggle");
    if (modalElement) {
      // Initialize modal using Bootstrap's JavaScript
      modalElement.className += " show";
      modalElement.style.display = "block"
      document.querySelector(".btn-close-white").addEventListener("click", function(){
        // Remove the "show" class from the modal element
        modalElement.classList.remove("show");
        // Set the display style to "none" to hide the modal element
        modalElement.style.display = "none";
    });

    }
    // document.querySelector(".btn-close-white").addEventListener("click", function(){
    //   modalElement.classList.remove = " show";
    //   modalElement.style.display = "none"
    // });
    // Cleanup: Remove event listener when component unmounts
    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
      }
    };
  }, [data]);

  const handleModalHidden = () => {

    const modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
    if (modalBackdrop) {
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    }
  };

  return (
    <>
      {data?.length > 0 && (
        <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1" style={{ display: "none" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div id="modalH" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  {data.map((item, index) => (
                     <div key={index} className={`carousel-item${index === 0 ? ' active' : ''}`}>
              <div className="modal-header">
                <h1>{item.title}</h1>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="card modalcard">
                  <div className="row">
                    <div className="card-body"> <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image[0]}`} className="img-fluid"  alt="Gandhi School"/>
                      <p>{item.description}</p>
                      <div className="clearfix"></div>
                        <br />
                      <div className="d-grid gap-2 d-md-flex justify-content-around d-block mx"> 
                      {item.attachments && item.attachments.length > 0 && ( <Link target="_blank" to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="btn btn-warning btn-sm">Attachment</Link>)}
                      {item.url && item.url.length > 0 && (<Link target="_blank" to={item?.url} className="btn btn-warning btn-sm">Click Here</Link>)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            ))}
          </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#modalH" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                <button className="carousel-control-next" type="button" data-bs-target="#modalH" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Popup
