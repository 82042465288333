import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const RulesDiscipline = () => {
  return (
     <>
       <Header/>
        <div className="innerslide RulesDisciplinebg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Rules of Discipline </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Rules of Discipline </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12"> 
               <ul className="mainlist">
                  <li>Pupils must show gentleness and courtesy to their companions. Refinement of manners, uprightness and self restraint should distinguish every student of this school.</li>
<li>Running, playing or shouting inside the school building or in the verandah is never allowed.</li>
<li>Pupils are responsible for the safe custody of their books and belongings. Each article should be marked with their names. It is not advisable for the pupils to bring money, mobile phones or other valuables to the school. The school will not be responsible for the loss of property.</li>
<li>All damages to school property must be made good at the cost of the offender.</li>
<li>Respect the belongings of another students.</li>
<li>Remember that school is yours. It belongs to each of us do not damage any property of write on the walls. Be proud of your school. Keep it clean.</li>
<li>All children should come to school clean and tidy uniform. Students who are not in proper uniform will not be allowed to enter the class rooms.</li>
<li>No pupil should leave the school premises during class hours without the knowledge and permission of the principal.</li>
<li>All pupils are responsible to the school authorities for their conduct in and out of the school.</li>
<li>Irregular attendance, in subordination teachers, habitual inattention to school work obstinacy in word and deed, willful and repeated breach of the school regulation are sufficient reasons for the dismissal of a students. The ward of those parents who avoid meeting the school authorities when advised to do so may not be allowed to attend the classes.</li>
<li>No pupil will be exempted from P.T without a doctor’s certificate countersigned by the principal.</li>
<li>Students are not allowed to be absent on the closing day/re-opening day or whenever there is school functions.</li>
<li>Enrolment in Gandhi High School implies the part of the pupils and parents/guardians willingness to comply with the requirements and regulations of the school.</li>
<li>All the decisions arrived at by the authorities regarding the promotion is final.</li>
<li>The student should obey the existing and the amended rules of the school. </li>
<li>It is obligatory to bring the hand book daily to the classes for all the students.</li>
<li>Bringing chewing gum, tobacco etc and using them in the school is prohibited. Carrying objectionable things like knife, crackers etc are banned.</li>
<li>Strict action will be taken against those students who are found disobeying the orders of the principal the teachers.</li>
<li>Students coming late may not be allowed to enter the school campus till the assembly gets over.</li>
<li>Students should never tamper with the entries made in the progress cards and school diaries.</li>
</ul>
               </div>
                
               {/*<div className="col-lg-6"><a data-magnify="gallery" data-src="" data-group="a" href="Images/rules_1.JPG"><img src="images/rules_1.jpg" className="img-fluid mainimg" alt="Gandhi High School, Sidhi, MP"/></a> </div>
               <div className="col-lg-6"><a data-magnify="gallery" data-src="" data-group="a" href="Images/rules_2.JPG"><img src="images/rules_2.jpg" className="img-fluid mainimg" alt="Gandhi High School, Sidhi, MP"/></a> </div>
  */} 
               </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default RulesDiscipline
