import React from 'react'
import Popup from'../Component/Popup'
import Header from'../Component/Header'
import HomeSlider from '../Component/HomeSlider'
import Topper from'../Component/Topper'
import Events from'../Component/Events'
import News from'../Component/News'
import Campuscare from'../Component/Campuscare'
import OnlineRegistration from'../Component/OnlineRegistration'
import Facilities from'../Component/Facilities'
import Gallery from'../Component/Gallery'
import Message from'../Component/Message'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom' 
const Home = () => {
  return (
    <> 
    <Campuscare/>
   <OnlineRegistration/>
       <Popup/>  
      <Header/> 
      <HomeSlider/>
      
      <div className="midsection">
        <div className="container">
           <div className="midsectionbox">
           <div className="row">
           <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="midbox">
                    <img src="/Images/mission.gif" className="img-fluid" alt="Gandhi High School, Sidhi - Madhya Pradesh"/>
                    <h5>Our  <span>mission</span></h5>
                    <div className="midboxdesc"><p>Our mission is to develop young men with active and creative minds, a sense of understanding and compassion for others, and the courage to act on their beliefs.</p></div>
                </div>
           </div>
           <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="midbox">
                    <img src="/Images/instruct.gif" className="img-fluid" alt="Gandhi High School, Sidhi - Madhya Pradesh"/>
                    <h5>Best <span>Tutor</span></h5>
                    <div className="midboxdesc"><p>A teacher is a person who helps others to acquire knowledge, competences or values. Informally the role of teacher may be taken on by anyone.</p></div>
                </div>
           </div>
           <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="midbox">
                    <img src="/Images/training.gif" className="img-fluid" alt="Gandhi High School, Sidhi - Madhya Pradesh"/>
                    <h5>Practical <span>Training</span></h5>
                    <div className="midboxdesc"><p>Practical training means studying which takes place in a company or business, offering the student the opportunity to adapt what she or he has learned in practice and develop professionally.</p></div>

                </div>
           </div>
           <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="midbox">
                    <img src="/Images/extracurricular.gif" className="img-fluid" alt="Gandhi High School, Sidhi - Madhya Pradesh"/>
                    <h5>Our <span>Programs</span></h5>
                    <div className="midboxdesc"><p>To work upon maximising student’s potential through self development programs like Yoga, Exercises, sports, recreation and culture travel.</p></div>
              </div>
                </div>
           </div>
        </div>
            </div>
            <div className="aboutmidsec">
            <div className="container-fluid fulwidth">
                  <div className="row">
                  <div className="col-lg-6">
                     <img src="/Images/about.png" className="img-fluid aboutgf"/>
                   </div>
                     <div className="col-lg-6">
                        <div className="maintitle">
                            <p>Highly Educated Teachers</p>
                            <h1>Our Management</h1>
                       </div>
                         <div className="aboutmiddesc">
                          <p>The faculty of Gandhi High School, shapes its special character. Each faculty member is both a teacher and a scholar, an expert in his or her own field. The faculty of Gandhi High School consists of 65 full time highly qualified teachers, who have extensive teaching experience in their respective fields.</p>
                          <p>All teachers are also involved in some school activity, as House Wardens, Class Reps, the Environment Council, Editorial Board, Cultural Department or Sports, to name a few. In fact, all teachers also share membership with students on various committees, school houses, and supervise individual or small-group research projects.</p>
                          <p>Students learn through teacher instruction, group activities and field trips. Seminars and workshops also form an integral part of the School Curriculum.</p>
                          </div> 
                          <Link to="#"> <img src="/Images/readmore.png" className="img-fluid"/> </Link>
                     
                     </div>
                   </div>
            </div>
         </div>
      </div>

      
      <div className="homemsg">
      <div className="container-fluid fulwidth">
        <div className="row">
           <div className="col-lg-3">
             <div className="heading">
              <img src="/Images/msg.gif" className="img-fluid"/>
              <h2> <img src="/Images/quotes.png" className="img-fluid"/><div><span>M</span>essage <br/> Section</div></h2>
            </div>
         </div>
         <div className="col-lg-9">

         <Message/>
         
        </div>
     </div>
     </div>
     </div>
      <div className="toopersec">
      <div className="container">
          <div className="row">
            <div className="col-lg-12">
                  <div className="heading">
                     <img src="/Images/topper.gif" className="img-fluid"/>
                      <h2><span>S</span>chool Toppers</h2>
                  </div>
            </div>
            <div className="col-lg-12 col-md-12 topper12bx">
              <Topper/>
              <div className="mainbtn"><Link to="/Toppers">  <img src="/Images/viewall.png" className="img-fluid"/></Link></div>
              </div> 
         </div>
         </div> 
     </div>
      <div className="homenews">
        <div className="container-fluid fulwidth">
            <div className="row">
            <div className="col-xl-4 col-lg-6">
              <div className="maintitle">
                    <p>What’s happening</p>
                    <h1>Latest News <Link to="/News"><i className="bi bi-chevron-double-right"></i></Link></h1>
              </div>
              <News/>
              </div>
              <div className="col-xl-8 col-lg-6 eventpd">
                <div className="maintitle">
                    <p>Events & updates</p>
                      <h1>Upcoming Events <Link to="/events"><i className="bi bi-chevron-double-right"></i></Link></h1>
                      
                </div>
                  <Events/>
              </div>
            </div>
        </div>
      </div>
      <div className="facilities">
      <div className="container">
           <div className="row">
                <div className="col-lg-12">
                   <div className="maintitle title">
                       <p>Facilities in the school</p>
                       <h1>Facilities Provided</h1>
                   </div>
               </div>
           </div>
      </div>
      <div className="container-fluid">
          <div className="row">
                  <Facilities/>  
           </div>
     </div>
    </div>
    <div className="homegallery">
    <div className="container-fluid fulwidth">
       <div className="row">
            <div className="col-lg-12">
                 <div className="maintitle title">
                      <p>School in media</p>
                      <h1>Gallery Section</h1>
                 </div>
                 <Gallery/>
                 <div className="mainbtn"><Link to="/Gallery">  <img src="/Images/viewgal.png" className="img-fluid"/></Link></div>
            </div>
       </div>
     </div>
  </div>
  <Footer/>
    </>
       
  )
}

export default Home
