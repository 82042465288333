import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Holidays = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Holidaysbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> List of Holidays </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>List of Holidays </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="table-responsive maintable">
                        <table className="table">
                                          <thead> 
                                                <tr>
												<th>S. No.</th>
												<th>Holiday</th>
												<th>Date</th> 
												</tr>
                                          </thead>
                                          <tbody>
                                                <tr>
												<td>1</td>
												<td>Ambedkar Jayanti</td>
												<td>14th April</td>
												</tr>  
												<tr>
												<td>2</td>
												<td>Parshuram Jayanti</td>
												<td>18th April</td>
												</tr>  
												<tr>
												<td>3</td>
												<td>Buddha Purnima</td>
												<td>30th April</td>
												</tr>  
												<tr>
												<td>4</td>
												<td>St. Thomas Day</td>
												<td>3rd July</td>
												</tr>  
												<tr>
												<td>5</td>
												<td>Independence Day</td>
												<td>15th August</td>
												</tr>  
												<tr>
												<td>6</td>
												<td>Id –ul-zuha</td>
												<td> 22nd August</td>
												</tr>  
												<tr>
												<td>7</td>
												<td>Raksha Bandhan</td>
												<td>26th August</td>
												</tr>  
												<tr>
												<td>8</td>
												<td>Muharram</td>
												<td>21st September</td>
												</tr>  
												<tr>
												<td>9</td>
												<td>Gandhi Jayanti</td>
												<td>2nd October</td>
												</tr>  
												<tr>
												<td>10</td>
												<td>Dussehra (Vijaya Dashmi)</td>
												<td>19th October</td>
												</tr>  
												<tr>
												<td>11</td>
												<td>Deepawali </td>
												<td>7th November</td>

												</tr>  
												<tr>
												<td>12</td>
												<td>Bhai Dooj</td>
												<td>9th November</td>
												</tr>  
												<tr>
												<td>13</td>
												<td>Milad-un-nabi</td>
												<td>21st November</td>
												</tr>  
												<tr>
												<td>14</td>
												<td>Guru Nakak Jayanti</td>
												<td>23rd November</td>
												</tr>  
												<tr>
												<td>15</td>
												<td>Christmas</td>
												<td>25th December</td>
												</tr>  
												<tr>
												<td>16</td>
												<td>Republic Day</td>
												<td>26th January</td>
												</tr>  
												<tr>
												<td>17</td>
												<td>Maha shivarati</td>
												<td>4th March</td>
												</tr>   
												<tr>
												<td>18</td>
												<td>Holi</td>
												<td>21st March</td>
												</tr>  
                                      </tbody></table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Holidays
