import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Prayers = () => {
  return (
     <>
       <Header/>
        <div className="innerslide prayerbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Prayers </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Prayers </h1>
                    </div>
                </div>
                <div className="row pray">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="verticaltabs">
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button className="nav-link active" id="v-pills-ourfather-tab" data-bs-toggle="pill" data-bs-target="#v-pills-ourfather" type="button" role="tab" aria-controls="v-pills-ourfather" aria-selected="true">OUR FATHER</button>
                  <button className="nav-link" id="v-pills-thisworld-tab" data-bs-toggle="pill" data-bs-target="#v-pills-thisworld" type="button" role="tab" aria-controls="v-pills-thisworld" aria-selected="false">THIS WORLD</button>
                  <button className="nav-link" id="v-pills-intthistime-tab" data-bs-toggle="pill" data-bs-target="#v-pills-intthistime" type="button" role="tab" aria-controls="v-pills-intthistime" aria-selected="false">IN HIS TIME</button>
                  <button className="nav-link" id="v-pills-aim-tab" data-bs-toggle="pill" data-bs-target="#v-pills-aim" type="button" role="tab" aria-controls="v-pills-aim" aria-selected="false">TRUE AIM OF EDUCATION</button>
                  <button className="nav-link" id="v-pills-beforeclass-tab" data-bs-toggle="pill" data-bs-target="#v-pills-beforeclass" type="button" role="tab" aria-controls="v-pills-beforeclass" aria-selected="false">PRAYER BEFORE CLASS</button>
                  <button className="nav-link" id="v-pills-afterclass-tab" data-bs-toggle="pill" data-bs-target="#v-pills-afterclass" type="button" role="tab" aria-controls="v-pills-afterclass" aria-selected="false">PRAYER AFTER CLASS</button>
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-ourfather" role="tabpanel" aria-labelledby="v-pills-ourfather-tab"><h3>OUR FATHER</h3>
                        <p className="text_center"> Our father in Heaven<br />
                           hallowed be your name<br />
                           your kingdom come, your will be done<br />
                           on earth as it is in Heaven.<br />
                           Give us this day our daily bread,<br />
                           and forgive us our trespasses<br />
                           as we forgive those who trespass against<br />
                           us and lead us not into<br /><br />
             
                           - Amen
                           </p>
              
                  </div>
                  <div className="tab-pane fade" id="v-pills-thisworld" role="tabpanel" aria-labelledby="v-pills-thisworld-tab"><h3>THIS WORLD</h3>
                       <p>This world you have made is a beautiful place, It tells the power of your love, we rejoice is the beauty of your world, from the seas to the heavens above  </p>
                       <p>The morning whispers of purity The evening of your place The thunder booms your exuberance In the awesome power you release.</p>
                       <p>The tenderness of a new born child The gentleness of the rain. Simplicity in a single cell. and complexity in a brain.</p>
                       <p>Yours truly, stillness rests in a silent pool. In infinity drifts in space. Your grandeur saddles the mountain tops. And we see your face in each face.</p>
                  </div>
                   <div className="tab-pane fade" id="v-pills-intthistime" role="tabpanel" aria-labelledby="v-pills-intthistime-tab"><h3>IN HIS TIME</h3>
                       <p>In his time, in his time He makes all things beautiful in his time. Lord, please show me every day As You’re teaching me your way That you do Just what you say in your time.  </p>
                       <p>In his time in your time you make is things beautiful in your time Lord my life to you I bring With its winter and its spring may I Fathom way thing in your time  </p>
                       <p>There’s time, there’s a time Both for sowing and for reaping there’s a time Time for losing, time for gain Time for joy and time for pain Every purpose under heaven has a time  </p>
                       <p>There are times, there are days weeks and months we can not understand gods ways. if for years we fail to Scan we’ll remember that he can, all the time  </p>
                            </div>
                   <div className="tab-pane fade" id="v-pills-aim" role="tabpanel" aria-labelledby="v-pills-aim-tab"><h3>TRUE AIM OF EDUCATION</h3>
                        <p className="text_center">Watch your thoughts,<br />
                           they become words.<br />
                           Watch your words,<br />
                           they become actions.<br />
                           Watch your actions,<br />
                           they become habits.<br />
                           Watch your habits,<br />
                           they become Character.<br />
                           Watch your Character,<br />
                           it becomes your destiny.  </p>
                  </div>
                   <div className="tab-pane fade" id="v-pills-beforeclass" role="tabpanel" aria-labelledby="v-pills-beforeclass-tab"><h3>PRAYER BEFORE CLASS</h3>
                         <p className="text_center">We offer Thee, God,<br />
                             Our studies and skills.<br />
                             Enlighten our minds,<br />
                             That we may know Thee and Thine.<br />
                             Move our hearts,<br />
                             That we may love thee and each other.  </p>
                  </div>
                   <div className="tab-pane fade" id="v-pills-afterclass" role="tabpanel" aria-labelledby="v-pills-afterclass-tab"><h3>PRAYER AFTER CLASS</h3>
                        <p>Dear God/we are going home. We thank you for your gifts. We promise you/ to be dutiful at home/as we wereat school. Bless us, to be good/ clever/and obedient children. Providence of God watch over us.  </p>
                  </div> 
                </div>
              </div>
             </div>
            </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Prayers
