import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const ParentTeacherCooperation = () => {
  return (
     <>
       <Header/>
        <div className="innerslide ParentTeacherCooperationbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Parent Teacher Co-operation </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Parent Teacher Co-operation </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12">
                <p>The success of the school depends on the co-operation of parents and teachers. The parents/ guardians are requested to keep a close contact with the school authorities to maintain a high standard of discipline and study.</p>
<p>They are to insist the regular attendance, punctuality, diligence at the home tasks, neatness, and cleanliness of the books and personal appearance of their wards. They are specially requested to go through the hand book in regular and check the progress report, regularity record and the diary ( for home tasks) in particular.</p>
<p>Parents are particularly expected to sign the principal’s and teacher’s remarks in the diary. Parents/ guardian are requested not to distract the teacher or the pupil from their duty by visits during the class hours. All dealings shall be conducted through the office of the school. Members of the staff can be interviewed during recess.</p>
<p>The valuable suggestions of the parents/ guardians are earnestly expected and for that, they may make use of the notification page of the hand book or contact personally.</p>
          </div> 
</div>
</div>
        </div>
       <Footer/>
     </>
  )
}

export default ParentTeacherCooperation
