import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const GemGoodBehaviour = () => {
  return (
     <>
       <Header/>
        <div className="innerslide GemGoodBehaviourbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Gem of Good Behaviour </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Gem of Good Behaviour  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                    <ul className="mainlist">
                        <li>Be in time for school daily.</li>
                        <li>Keep your hands and face washed, your hair combed and teeth brushed.</li>
                        <li>Keep away from chewing- gum and the sort.</li>
                        <li>Do not write on walls and furniture.</li>
                        <li>Keep your hands and feet away from the walls. </li>
                        <li>Put papers and fruits skins in proper container.</li>
                        <li>Do not play in verandahs and classrooms.</li>
                        <li>Say “please” when you want something.</li>
                        <li>Say “Thank You” when you receive something.</li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <ul className="mainlist">
                            <li>If you have to interrupt an adult in conversation, say “please excuse me”.</li>
                            <li>Do not look into class rooms as you pass by.</li>
                            <li>Do not stand near the elder people when they are talking together.</li>
                            <li>Allow older people to go before.</li>
                            <li>Cover your books with brown paper and label them.</li>
                            <li>Do not buy eatables from vendors during the recess. </li>
                            <li>Wish your teachers in and out of school.</li>
                            <li>Honesty, truthfulness, respect for the public, good spirit of service and brotherliness should be the hallmark of your character and conduct.</li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default GemGoodBehaviour
