import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const ManagementStaff = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> Management </li>
                <li> Management Staff </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Management Staff </h1>
                    </div>
                </div>
                 <div className="principalofficesec"> 
                   <div className="principaloffice">
                       <div className="principalofficeimg">
                           <img src="/Images/bishop.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                       <div className="principalofficedesc">
                            <h3>Bishop Joseph Kodakallil</h3>
                            <p> President </p>
                            </div>
                            </div>
                            <div className="principaloffice">
                                    <div className="principalofficeimg">
                                        <img src="/Images/manager.jpg" className="img-fluid"  alt="Gandhi High School, Sidhi, MP"/></div>
                                    <div className="principalofficedesc">
                            <h3>Fr. Justin Thundiyil</h3>
                            <p>Manager</p>
                            </div>
                            </div>
                            <div className="principaloffice">
                                    <div className="principalofficeimg">
                                        <img src="/Images/principal.jpg" className="img-fluid"  alt="Gandhi High School, Sidhi, MP"/></div>
                                    <div className="principalofficedesc">
                            <h3>Sr. Lincey</h3>
                            <p>Principal</p>
                            </div>
                            </div>
                            <div className="principaloffice">
                                    <div className="principalofficeimg">
                                        <img src="/Images/Vice-Principal.jpg" className="img-fluid"  alt="Gandhi High School, Sidhi, MP"/></div>
                                    <div className="principalofficedesc">
                            <h3>Sr. Helena </h3>
                            <p> Vice Principal</p>
                            </div>
                            </div>
                            <div className="principaloffice">
                                    <div className="principalofficeimg">
                                        <img src="/Images/KG-Primary-Incharge.jpg" className="img-fluid"  alt="Gandhi High School, Sidhi, MP"/></div>
                                    <div className="principalofficedesc">
                            <h3>Sr. Boni</h3>
                            <p>KG Incharge</p>
                            </div>
                            </div>    
                            </div>
                        <div className="row">
                            <div className="col-lg-12">
                               <h2>UDT</h2>
                             </div>
                        </div>
                        <div className="principalofficesec"> 
                        <div className="principaloffice">
                                <div className="principalofficeimg">
                                    <img src="/Images/Mr. Thomas V Abraham - UDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                <div className="principalofficedesc"><h3>Mr. Thomas V Abraham</h3><p>---</p></div></div>
                        <div className="principaloffice">
                                <div className="principalofficeimg">
                                    <img src="/Images/3. Mr. Badri Narayan Mishra UDT M.A, D.ed.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                <div className="principalofficedesc"><h3>Mr. Badri Narayan Mishra</h3><p>M.A, D.ed</p></div></div>
                        <div className="principaloffice">
                                <div className="principalofficeimg">
                                    <img src="/Images/4. Mr. Akhileshwar  Prasad Pandey M.A, B.ed UDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                <div className="principalofficedesc"><h3>Mr. Akhileshwar  Prasad Pandey</h3><p>M.A, B.ed</p></div></div>
                        <div className="principaloffice">
                                <div className="principalofficeimg">
                                    <img src="/Images/6. Punam Singh B.Sc, B.ed.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                <div className="principalofficedesc"><h3>Punam Singh</h3><p>B.Sc, B.ed</p></div></div>
                        <div className="principaloffice">
                                <div className="principalofficeimg">
                                    <img src="/Images/8. Ms. Khushboo Singh Jaiswal M.Sc, B.ed UDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                <div className="principalofficedesc"><h3>Ms. Khushboo Singh Jaiswal</h3><p>M.Sc, B.ed</p></div></div>
                         </div>
                         <div className="row">
                            <div className="col-lg-12">
                               <h2>LDT</h2>
                             </div>
                        </div>
                        <div className="principalofficesec"> 
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/Ms. Priya Pandey - LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Ms. Priya Pandey</h3><p>---</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/Ms. Kiran Patel - LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Ms. Kiran Patel</h3><p>---</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/Ms. Keerti Tiwari - LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Ms. Keerti Tiwari</h3><p>---</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/Ms. Elsa Jose - LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Ms. Elsa Jose</h3><p>---</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/Ms. Diksha Singh - LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Ms. Diksha Singh</h3><p>---</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/Ms. Avantika Pawar - LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Ms. Avantika Pawar</h3><p>---</p></div></div>



                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/Mrs. Anuradha Pandey - LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Anuradha Pandey</h3><p>---</p></div></div>

                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/11. Mrs. Sreedevi Nair  M.A, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Sreedevi Nair</h3><p>M.A, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/12. Mrs. Sushma Shukla M.A, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Sushma Shukla</h3><p>M.A, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/13. Mr. Pawan Kumar Dwivedi M.Sc, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mr. Pawan Kumar Dwivedi</h3><p>M.Sc, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/14. Mrs. Sulochana Gupta B.A, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Sulochana Gupta</h3><p>B.A, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/15. Mrs. Priyansha Tripathi M.Sc, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Priyansha Tripathi</h3><p>M.Sc, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/16. Mrs. Pooja Dwivedi M.A, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Pooja Dwivedi</h3><p>M.A, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/17. Mrs. Pratima Pandey M.A, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Pratima Pandey</h3><p>M.A, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/18. Mr. Ashwani Kuamr Vishwakarma B.A, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mr. Ashwani Kuamr Vishwakarma</h3><p>B.A, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/19. Ms. Swapnil Shukla B.Sc, B.ed LDT (Lib.).jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3> Swapnil Shukla</h3><p>B.Sc, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/21. Mr. Ankit  Singh Chauhan B.Sc, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mr. Ankit  Singh Chauhan</h3><p>B.Sc, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/22. Mrs. Seema Shukla M.Sc, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Seema Shukla</h3><p>M.Sc, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/23. Suruchi Singh Gaharwar B.Sc, M.Sc. B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Suruchi Singh Gaharwar</h3><p>B.Sc, M.Sc. B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/24. Hema Ahuja  B.Com, D.E.L.Ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Hema Ahuja</h3><p>B.Com, D.E.L.Ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/25. Mrs. Shweta Trivedi M.Sc, B.ed, PGDCA LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Shweta Trivedi</h3><p>M.Sc, B.ed, PGDCA</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/26. Nisha Singh M.Com, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Nisha Singh</h3><p>M.Com, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/27. Mrs. Sharda Shukla B.Com, B.ed, LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Sharda Shukla</h3><p>B.Com, B.ed</p></div></div>


                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/35. Aradhana Singh M.Sc, B.ed, LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Aradhana Singh</h3><p>M.Sc, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/36. Mrs. Ashu Singh M.Com, B.ed, LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Ashu Singh</h3><p>M.Com, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/38. Mrs. Shalini Singh Parihar M.Sc, B.ed , LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Shalini Singh</h3><p>M.Sc, B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/39. Mrs. Mohita Devi Misra M.A, B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Mohita Devi Misra</h3><p>M.A, B.ed</p></div></div>



                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/45. Mrs. Najiya Bano M.Sc, CS, D.E.l, Ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Najiya Bano</h3><p>M.Sc, CS, D.E.l, Ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/46. Mrs. Harshita Singh Chauhan M.Com D.E.L.Ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Harshita Singh Chauhan</h3><p>M.Com D.E.L.Ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/47. Mrs. Ritika Syal  M.A. B.ed  LDT.jpeg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Ritika Syal</h3><p>M.A. B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/69. Punam Kumari  M.A. B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Punam Kumari</h3><p>M.A. B.ed</p></div></div>
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/73. Mrs. Saroj Tiwari B.A B.ed LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc"><h3>Mrs. Saroj Tiwari</h3><p>B.A B.ed</p></div></div>

                                </div>
                                
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>KGT</h2>
                            </div>
                        </div>
                        <div className="principalofficesec"> 
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/Ms. Akanksha Gupta - KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Ms. Akanksha Gupta</h3><p>----</p></div></div>
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/Mrs. Chitrangini Singh -KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Mrs. Chitrangini Singh</h3><p>---</p></div></div>
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/49. Mrs. Preeti Singh Chauhan M.Com, D.ed KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Mrs. Preeti Singh Chauhan</h3><p>M.Com, D.ed</p></div></div>
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/50. Mrs. Anamika Pandey M.Com, D.ed KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Mrs. Anamika Pandey</h3><p>M.Com, D.ed</p></div></div>
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/51. Ankita Singh Baghel MSW, D.ed  KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Ankita Singh Baghel</h3><p>MSW, D.e</p></div></div>
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/52. Mrs. Ritu Singh M.Com, D.ed KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Mrs. Ritu Singh</h3><p>M.Com, D.ed</p></div></div>
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/53. Ms. Shivani Singh Chauhan B.Com, D.E.l.Ed KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Ms. Shivani Singh Chauhan</h3><p>B.Com, D.E.l.Ed</p></div></div>
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/55. Ms. Akanksha Singh Chauhan M.Sc , B.ed KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Ms. Akanksha Singh Chauhan</h3><p>M.Sc , B.ed</p></div></div>
                        <div className="principaloffice">
                            <div className="principalofficeimg">
                                <img src="/Images/56. Mrs. Rashmi Kushwaha M.Sc, B.ed KGT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                            <div className="principalofficedesc"><h3>Mrs. Rashmi Kushwaha</h3><p>M.Sc, B.ed</p></div></div>

                        </div>  
                        <div className="row managementbox">
                        <div className="col-lg-4 col-md-6">
                              <h2>UDC</h2> 
                              <div className="principalofficesec"> 
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/LDC_2.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc">
                                           <h3>Mr. Subhash Kumar Vishwakarma -  UDC</h3>
                                           <p>---</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                         <h2>LDC</h2> 
                        <div className="principalofficesec"> 
                           <div className="principaloffice">
                                <div className="principalofficeimg">
                                    <img src="/Images/LDC_1.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                <div className="principalofficedesc">
                        <h3>Mr. Sandeep Kumar Verma</h3>
                        <p>---</p>
                        </div>
                        </div>
                        </div>
                        </div>
                         
                        <div className="col-lg-4 col-md-6">
                             <h2>Sport PTI</h2> 
                             <div className="principalofficesec"> 
                                <div className="principaloffice">
                                        <div className="principalofficeimg">
                                            <img src="/Images/pti.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                        <div className="principalofficedesc">
                                <h3>Mr. Mohammad Mamoon Ansari</h3>
                                <p>PTI</p>
                                </div>
                                </div>
                            </div>  
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                         <h2>Music</h2> 
                        <div className="principalofficesec"> 
                        
                        <div className="principaloffice">
                         <div className="principalofficeimg"> <img src="/Images/32. Mr. Rakesh Kumar Namdeo M.A. UGC Net Music LDT.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                <div className="principalofficedesc"><h3>Mr. Rakesh Kumar Namdeo</h3><p>M.A. UGC Net Music</p></div>
                        </div>
                        </div>  
                        </div>
                        <div className="col-lg-4 col-md-6">
                         <h2>IT Admin</h2> 
                        <div className="principalofficesec"> 
                        <div className="principaloffice">
                                <div className="principalofficeimg">
                                    <img src="/Images/it-admin.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                <div className="principalofficedesc">
                        <h3>Mr. Harshit Benjamin  </h3>
                        <p>IT, B.Com, PGDCA, Network Engineering</p>
                        </div>
                        </div>
                        </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Supporting Staff</h2>
                            </div>
                       </div>
                       <div className="principalofficesec"> 
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_1.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mr. Bhailal Goswami ( Garud )</h3>
                                    <p>---</p>
                                    <h5>---</h5>
                                    </div>
                                    </div>
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_3.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mrs. Butta Shivnath</h3>
                                    <p>Aaya</p>
                                    <h5>5th</h5>
                                    </div>
                                    </div>
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_4.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mrs. Geeta Kori</h3>
                                    <p>Sweeper</p>
                                    <h5>5th</h5>
                                    </div>
                                    </div>
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_5.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mrs. Phoolkali Kori</h3>
                                    <p>Sweeper</p>
                                    <h5>5th</h5>
                                    </div>
                                    </div>
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_6.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mrs. Munni Kori</h3>
                                    <p>Sweeper</p>
                                    <h5>5th</h5>
                                    </div>
                                    </div>
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_7.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mr. Ram Narayan</h3>
                                    <p>Guard</p>
                                    <h5>10th</h5>
                                    </div>
                                    </div>
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_8.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mrs. Ramdulari</h3>
                                    <p>Sweeper</p>
                                    <h5>5th</h5>
                                    </div>
                                    </div>
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_9.jpg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mrs. Sadhana Yadav</h3>
                                    <p>Aaya</p>
                                    <h5>8th</h5>
                                    </div>
                                    </div>
                                    <div className="principaloffice">
                                            <div className="principalofficeimg">
                                                <img src="/Images/support_staff_10.jpeg" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/></div>
                                            <div className="principalofficedesc">
                                    <h3>Mr. Deepak Goswami</h3>
                                    <p>Peon</p>
                                    <h5>10th, 12th </h5>
                                    </div>
                                    </div>
                                    </div>
                </div>
            </div> 
       <Footer/>
     </>
  )
}

export default ManagementStaff
