import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Pledge = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Pledgebg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Pledge </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Pledge </h1>
                    </div>
                </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Pledge
