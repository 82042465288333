import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const VisionMission = () => {
  return (
     <>
       <Header/>
        <div className="innerslide VisionMissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Vision and Mission </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Vision and Mission </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12"> 
               <p>Our vision and mission is to facilitate students to hold high, the torch of learning and keep it burning bright, spread the light of wisdom and Human values into the dark corners, banishing ignorance, prejudices and fears. The institution facilitates the harmonious development of the physical moral, spiritual and intellectual endowments of the students. To this, while giving the greater importance to the acquiring of knowledge and passing of competitive examinations and equal importance is given to character formation and personality development by forming in them habits of service, studiousness, piety, discipline and self reliance. Above all, the school ensures that its students learn to live with others, irrespective of race, caste, faith, or status.</p>
               
               </div>
                
    
               </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default VisionMission
