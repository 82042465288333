import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const SiteMap = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Sitemap </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Sitemap </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                <h3 class="subheading"> Home </h3>
                <ul className='sitemapli'>
                    <li><Link to="/">  Back to Home</Link></li>  
                </ul> 
                </div> 
                
<div className="col-md-12">
<h3 class="subheading">  School </h3>
<ul className='sitemapli'>
	<li><Link to="/Prayers">Prayers</Link></li>
	<li><Link to="/Preamble">Preamble</Link></li>
	<li><Link to="/ComingSoon">Pledge</Link></li>
	<li><Link to="/VisionMission">Vision and Mission</Link></li>
	<li><Link to="/Admission">Admission</Link></li>
	<li><Link to="/GemGoodBehaviour">Gems of good behaviour</Link></li>
	<li><Link to="/RulesDiscipline">Rules of Discipline</Link></li>
	<li><Link to="/ParentTeacherCooperation">Parent teacher Co-operation</Link></li>
	<li><Link to="/EducationalRightsMinorities">The Educational Rights Of The Minorities</Link></li>
	<li><Link to="/Holidays">List of Holidays</Link></li>
	<li><Link to="/Examination">Unit /Quaterly / Halfyearly Examinations</Link></li> 
</ul> 
</div>
<div className="col-md-12">
<h3 class="subheading">   Management  </h3>
<ul className='sitemapli'>
	<li><Link to="/BishopMessage">Bishop's Message </Link></li>
	<li><Link to="/ManagersMessage">Manager's Message </Link></li>
	<li><Link to="/PrincipalsMessage">Principal's Message </Link></li>
	<li><Link to="/ManagementStaff">Management &amp; Staff </Link></li>
	<li><Link to="/ComingSoon">Activities </Link></li>
</ul>
</div>
<div className="col-md-12">
<h3 class="subheading">  Office Bearers </h3>
<ul className='sitemapli'>
	<li><Link to="/ComingSoon"> Office Bearers </Link></li>
	<li><Link to="/SchoolCabinet"> School Cabinet  </Link></li> 
</ul> 
</div>
<div className="col-md-12">
<h3 class="subheading"> Academic </h3>
<ul className='sitemapli'>
	<li><Link to="/News">  News </Link></li>
	<li><Link to="/ComingSoon"> Syllabus </Link></li>
	<li><Link to="/pdf/timetable.pdf" target="_blank">Timetable </Link></li>
	<li><Link to="/Toppers">Board Toppers </Link></li>
	<li><Link to="/OtherToppers">Class Toppers </Link></li>
</ul> 
</div>
<div className="col-md-12">
<h3 class="subheading"> Gallery </h3>
<ul className='sitemapli'>
	<li><Link to="/Gallery">  Gallery </Link></li> 
</ul> 
</div>
<div className="col-md-12">
<h3 class="subheading"> Information </h3>
<ul className='sitemapli'>
	<li><Link to="/Pphelp">  Parent Portal </Link></li> 
	<li><Link to="/ErpSoftware">  Erp Software </Link></li> 
</ul> 
</div> 
<div className="col-md-12">
<h3 class="subheading"> Kindergarten </h3>
<ul className='sitemapli'>
	<li><Link to="/Kindergarten">  Kindergarten </Link></li> 
</ul> 
</div> 
                  
<div className="col-md-12">
<h3 class="subheading"> Contact Us </h3>
<ul className='sitemapli'>
	<li><Link to="/ContactUs">  Contact Us </Link></li> 
</ul> 
</div> 
 
                </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default SiteMap
