import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Admission = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Admission </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Admission </h1>
                    </div>
                </div>
                <div className="row">
 <div className="col-lg-12"> 
 	<p><strong>Admission to class 1 starts by the end of March.</strong></p>
<p>Admissions are done on the basis of an interview and entrance test. The merit of the student is the sole criteria for selection. Birth Certificate/Declaration are compulsory.
Date of birth once registered cannot be altered. For the admission of class 1 preference will be given to those who pass Upper K.G of this school.</p>
</div>
 
  {/* <div className="col-lg-6"><a data-magnify="gallery" data-src="" data-group="a" href="Images/admission_1.JPG"><img src="images/admission_1.jpg" className="img-fluid mainimg" alt="Gandhi High School, Sidhi, MP"/></a> </div>
<div className="col-lg-6"><a data-magnify="gallery" data-src="" data-group="a" href="Images/admission_2.JPG"><img src="images/admission_2.jpg" className="img-fluid mainimg" alt="Gandhi High School, Sidhi, MP"/></a> </div>
  */}
</div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Admission
