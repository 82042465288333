import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const EducationalRightsMinorities = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Educational Rights of The Minorities </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Educational Rights  of The  Minorities </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12"> 
               <ul className="mainlist">
                   <li>Right to establish and administer educational institutions of their choice.</li>
                   <li>Right to appoint Governing Bodies of their choice.</li>
                   <li>Right to admit students of their communities first.</li>
                   <li>Right to appoint principals, Headmasters and teachers of their choice, provided they have minimum qualification prescribed by the Government.</li>
                   <li>Right to take reasonable fees from the student, if the educational institutions are not receiving grant- or –aid from the Government.</li>
                   <li>Right to select medium of instruction.</li>
                   <li>Right to fix uniform dress for the students.</li>
                   <li>Right to teach religion and conduct worship for the children of the minority community.</li>
                   <li>Right to admit either boy or girl or both.</li>
                   <li>Right not to be nationalized by the Government.</li>
               </ul>
               </div>
                
               {/* <div className="col-lg-6"><a data-magnify="gallery" data-src="" data-group="a" href="Images/Educational_Rights_1.JPG"><img src="images/Educational_Rights_1.jpg" className="img-fluid mainimg" alt="Gandhi High School, Sidhi, MP"/></a> </div>
               <div className="col-lg-6"><a data-magnify="gallery" data-src="" data-group="a" href="Images/Educational_Rights_2.JPG"><img src="images/Educational_Rights_2.jpg" className="img-fluid mainimg" alt="Gandhi High School, Sidhi, MP"/></a> </div>
  */} 
               </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default EducationalRightsMinorities
