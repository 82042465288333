import React from 'react'
import Facilitiesprop from'../Pages/Facilitiesprop'
const Facilities = () => {
  return (
     <>
       <Facilitiesprop image="/Images/facilities1.gif" title="Class rooms equipped with Audio Visual Aids" desc="Each classroom from nursery to senior secondary is provided with audio visual aids. Teachers, as per the requirement of curriculum and syllabus prepare digital lesson plan and use the same through this facility. This can make the learning interesting and concepts easy and clean." />  
       <Facilitiesprop image="/Images/facilities2.gif" title="Fully Fledged Science Laboratories in accordance with the Guidance of Council" desc="We have spacious and sufficient number of physics, chemistry and biology laboratories with all the required apparatus and other necessary requirements to cater to the needs of students. Even students of high school classes are taken to the laboratories occasionally to make them familiar with the apparatus and system." />
       <Facilitiesprop image="/Images/facilities3.gif" title="Computer Lab for Different Sections" desc="School has different computer labs for different sections so that all the students can make use of the computer lab. The school makes sure that every individual student gets the facility to practice on a separate system." />
       <Facilitiesprop image="/Images/facilities4.gif" title="Library" desc="School library is renovated according to the need. It is replenished every year with additional supply of books on different subjects and different themes. Library has a spacious reading room where students can look up reference books on various topics and read newspapers and periodicals. Children in and above class six are permitted to take books home according to the rules and regulations." />
     </>
  )
}

export default Facilities
