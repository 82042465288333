import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const BishopMessage = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li>  Management  </li>
                <li> Bishop's Message  </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Bishop's Message </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="msgbox">
                            <img src='/Images/bishop.jpg' alt="Gandhi High School, Sidhi, MP" className="img-fluid"/>
                        </div>
                        <p>School is an educational institution of excellence and it is engaged in forming the future generations through a value based education system. Its service and dedication in the educational sector for more than Fifty two is remarkable. It has crossed many mile stones in providing quality education to its children. The well qualified and committed team of staff is the strength of Gandhi High School Sidhi. The Satna Diocesan Society is proud of its achievements and happy to be at the service of the people of Sidhi. I earnestly wish that the Gandhi high school students and its alumini may brighten our nation. They may be illumined and inspired by the values of truthfulness, humility, respect, tolerance and sincerity to fight against the evils prevalent in the society. <br/><strong> I wish all the best to Gandhi High School family and pray for God's abundant blessings.</strong></p>
                        <p className="name">Rev. Bishop Mar Joseph Kodakallil<span>
                
                        Bishop  </span></p>
                    </div>
            </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default BishopMessage
