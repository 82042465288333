import Home from'./Pages/Home'
import Prayers from'./Pages/Prayers'
import Preamble  from'./Pages/Preamble'
import Pledge from'./Pages/Pledge'
import VisionMission  from'./Pages/VisionMission'
import Admission from'./Pages/Admission'
import GemGoodBehaviour from'./Pages/GemGoodBehaviour'
import RulesDiscipline  from'./Pages/RulesDiscipline'
import ParentTeacherCooperation from'./Pages/ParentTeacherCooperation'
import EducationalRightsMinorities from'./Pages/EducationalRightsMinorities'
import Holidays from'./Pages/Holidays'
import Examination from'./Pages/Examination' 
import ManagementStaff from'./Pages/ManagementStaff'
import ManagersMessage from'./Pages/ManagersMessage'
import BishopMessage from'./Pages/BishopMessage'
import PrincipalsMessage from'./Pages/PrincipalsMessage'
import ContactUs from'./Pages/ContactUs'
import Toppers from'./Pages/Toppers'
import OtherToppers from'./Pages/OtherToppers'
import Pphelp from'./Pages/Pphelp'
import ErpSoftware from'./Pages/ErpSoftware'
import ComingSoon from'./Pages/ComingSoon'
import VideoGallery from'./Pages/VideoGallery'
import SubGallery from'./Pages/SubGallery'
import Gallery from'./Pages/Gallery'
import Events from'./Pages/Events'
import News from'./Pages/News'
import SchoolCabinet from'./Pages/SchoolCabinet'
import Kindergarten from'./Pages/Kindergarten'
import SiteMap from'./Pages/SiteMap'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function App() { 
  return (
      <>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>} />
       <Route path="/Prayers" element={<Prayers/>} />
       <Route path="/Preamble" element={<Preamble/>} />
       <Route path="/Pledge" element={<Pledge/>} />
       <Route path="/VisionMission" element={<VisionMission/>} />
       <Route path="/Admission" element={<Admission/>} />
       <Route path="/GemGoodBehaviour" element={<GemGoodBehaviour/>} />
       <Route path="/RulesDiscipline" element={<RulesDiscipline/>} />
       <Route path="/ParentTeacherCooperation" element={<ParentTeacherCooperation/>} />
       <Route path="/EducationalRightsMinorities" element={<EducationalRightsMinorities/>} />
       <Route path="/Holidays" element={<Holidays/>} />
       <Route path="/Examination" element={<Examination/>}/>
       <Route path="/ManagementStaff" element={<ManagementStaff/>}/>
       <Route path="/ManagersMessage" element={<ManagersMessage/>}/>
       <Route path="/BishopMessage" element={<BishopMessage/>}/>
       <Route path="/PrincipalsMessage" element={<PrincipalsMessage/>}/>
       <Route path="/ContactUs" element={<ContactUs/>}/>
       <Route path="/Toppers" element={<Toppers/>}/>
       <Route path="/OtherToppers" element={<OtherToppers/>}/>
       <Route path="/ErpSoftware" element={<ErpSoftware/>}/>
       <Route path="/Pphelp" element={<Pphelp/>}/>
       <Route path="/ComingSoon" element={<ComingSoon/>}/>
       <Route path="/VideoGallery" element={<VideoGallery/>}/>
         <Route path="/SubGallery" element={<SubGallery/>}/>
         <Route path="/Gallery" element={<Gallery/>}/>
         <Route path="/Events" element={<Events/>}/>
         <Route path="/News" element={<News/>}/>
         <Route path="/Kindergarten" element={<Kindergarten/>}/>
         <Route path="/SchoolCabinet" element={<SchoolCabinet/>}/>
         <Route path="/SiteMap" element={<SiteMap/>}/>
         
      </Routes>
     </BrowserRouter>
      </>
  )
}

export default App;
