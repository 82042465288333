import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import Header from "../Component/Header";
import { Link } from "react-router-dom";
import Footer from "../Component/Footer";

const Toppers = () => {
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Define fetchData function
  const fetchData = async (year) => {
   try {
     const toppersData = await getTopper();
     const filteredData = toppersData.filter(
       (item) => item.sessionYear === year && item.class === "X"
     );
 
     // Sort data by percentage in descending order
     const sortedData = filteredData.sort((a, b) => b.division - a.division);
 
     setData(sortedData);
 
     const years = new Set(toppersData.map((item) => item.sessionYear));
     setYearRanges([...years]);
   } catch (error) {
     console.error("Error fetching data:", error);
   }
 };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const yearRange = `${lastYear}-${currentYear}`;
    setSelectedYear(yearRange);
    fetchData(yearRange); // Initial fetch on component mount
  }, []);

  useEffect(() => {
    if (!selectedYear) return;
    fetchData(selectedYear); // Refetch data based on selected year
  }, [selectedYear]);

  useEffect(() => {
    // Filter data based on search query
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
    // Sort filtered data by division in descending order
    const sortedData = filteredData.sort((a, b) => b.division - a.division);
    
    setData(sortedData); // Update data state with filtered and sorted data
  }, [searchQuery]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Header />
      <div className="innerslide Admissionbg">
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Toppers X</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Toppers X</h1>
            </div>
            <div className="col-lg-6">
              <div className="chooseyear">
                <select
                  id="chooseddlYear"
                  onChange={handleYearChange}
                  value={selectedYear}
                >
                  <option value="">Select Years</option>
                  {yearRanges.map((yr, index) => (
                    <option key={index} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div id="showTwo" className="myDiv1 selected_year">
            <div className="topperssec">
              {data.length > 0 ? (
                data.map((item, index) => (
                  <div className="topperdiv" key={index}>
                    <div className="topperper">
                      <p>{item.division}</p>
                    </div>
                    <div className="topperdivimg">
                      <img
                        src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`}
                        className="img-fluid"
                        alt={`Toppers ${item.name}`}
                      />
                    </div>
                    <div className="topperdivdesc">
                      <p className="toppername">
                        {item.name} <span>{item.stream}</span>
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="topperdiv">
                  <div className="topperper">
                    <p>%</p>
                  </div>
                  <div className="topperdivimg">
                    <img
                      src="/Images/topper.jpg"
                      className="img-fluid"
                      alt="Placeholder"
                    />
                  </div>
                  <div className="topperdivdesc">
                    <p className="toppername">
                      Name <span>Subject</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Toppers;
