import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const ComingSoon = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Coming Soon </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Coming Soon  </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 circular_sec">
                    <img src="/Images/coming-soon.jpg" alt="Gandhi High School, Sidhi, MP" className="coming_soon img-fluid"/>
               </div>
               </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default ComingSoon
