import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Examination = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Examination </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Examination</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="table-responsive maintable">
                        <table className="table">
                                         <thead><tr>
                                            <th>S.NO.</th>
                                            <th>Examinations</th>
                                            <th>Months</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          <tr>
                                            <td> 1</td>
                                            <td>Unit Test 1</td>
                                            <td>Last week of   April</td>
                                          </tr>
                                          <tr>
                                            <td> 2</td>
                                            <td>Unit Test 2 </td>
                                            <td>2nd   week of July </td>
                                          </tr>
                                          <tr>
                                            <td> 3.</td>
                                            <td>Unit Test 3</td>
                                            <td>2nd   week of August</td>
                                          </tr>
                                          <tr>
                                            <td> 4.</td>
                                            <td>Quarterly   Examination</td>
                                            <td>3rd   week of September</td>
                                          </tr>
                                          <tr>
                                            <td> 5.</td>
                                            <td>Unit Test 4</td>
                                            <td>Last week of   October</td>
                                          </tr>
                                          <tr>
                                            <td> 6.</td>
                                            <td>Unit Test 5 </td>
                                            <td>2nd   week of November</td>
                                          </tr>
                                          <tr>
                                            <td> 7. </td>
                                            <td>Half Yearly   Examination</td>
                                            <td>2nd   week of December</td>
                                          </tr>
                                          <tr>
                                            <td> 8.</td>
                                            <td>1st   Pre-Board –Class X</td>
                                            <td>3rd   week of January</td>
                                          </tr>
                                          <tr>
                                            <td> 9. </td>
                                            <td>Unit Test 6</td>
                                            <td>3rd   week of January</td>
                                          </tr>
                                          <tr>
                                            <td> 10.</td>
                                            <td>2nd   Pre-Board – Class X</td>
                                            <td>1st   week of February</td>
                                          </tr>
                                          <tr>
                                            <td> 11.</td>
                                            <td>Annual   Examinations</td>
                                            <td>1st   week of March</td>
                                          </tr>
                                          <tr>
                                            <td> 12.</td>
                                            <td>Board   Examinations</td>
                                            <td>March</td>
                                          </tr>
                                      </tbody></table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Examination
